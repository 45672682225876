
import { Options, setup, Vue } from 'vue-class-component'
import ProductDetail from '@/components/dialogs/ProductDetail.vue'
import ErroDialog from '@/components/dialogs/ErrorDialog.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import { inject, onMounted } from '@vue/runtime-core'
import { useConfirm } from 'primevue/useconfirm'

import { pinpadService, initConfig } from '@/utils/payment/pinpad'
import router from './router'
import { useStore } from 'vuex'
import { Actions } from './config/store.config'
import appconfigService from './services/appconfig/appconfig.service'

@Options({
  components: {
    ProductDetail,
    ConfirmDialog,
    ErroDialog
  }
})
export default class App extends Vue {
  context = setup(() => {
    const store = useStore()
    const confirm = useConfirm()
    const flavor = inject<string>('FLAVOR')

    onMounted(async () => {
      import(`@/assets/flavors/${flavor}/styles/bundle.scss`)
      const appconfig = await appconfigService.get()
      const timerSeconds = +(
        appconfig.DataConfig?.constants?.constantDefaultKioskResetTime ?? 90
      )

      const body = document.querySelector('body')
      body?.addEventListener('contextmenu', (ev) => {
        ev.preventDefault()
      })

      const reset = () => {
        store.dispatch(Actions.HIDE_ALL_DIALOG)
        store.dispatch(Actions.HIDE_ALL_ERRORS)

        confirm.close()

        router.push('/')
      }

      let timer = setInterval(reset, timerSeconds * 1000)
      body?.addEventListener('click', () => {
        clearInterval(timer)
        timer = setInterval(reset, timerSeconds * 1000)
      })

      if (initConfig.ignoreOnStart) {
        return
      }

      if (!initConfig) {
        confirm.require({
          message: 'Falta realizar configuración de pinpad',
          header: 'PINPAD',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Aceptar',
          rejectClass: 'btn-clean none',
          accept: () => {
            window.location.reload()
          },
          reject: () => {
            window.location.reload()
          }
        })
      } else {
        pinpadService
          .init()
          .then((data) => {
            console.log(data)
          })
          .catch((err) => {
            confirm.require({
              message: err,
              header: 'Lo sentimos',
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: 'Aceptar',
              rejectClass: 'btn-clean none',
              accept: () => {
                window.location.reload()
              },
              reject: () => {
                window.location.reload()
              }
            })
          })
      }
    })

    return {}
  })
}
