import axios from 'axios'
import authAxios from '@/utils/authaxios.util'
import ServiceHandler from '@/utils/servicehandler.util'
import ServiceConfig from '@/config/services.config'
import appconfigService from '../appconfig/appconfig.service'
import UserLoginData from '@/models/responses/userlogin.response'
import ModuleConfig from '@/config/modules.config'

class AuthService {
  public async signupGuest (uuid: string) : Promise<UserLoginData> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CLIENT_SIGNUP_GUEST)

      return axios
        .post('/api/auth/signupGuest', {
          target: serviceUrl,
          hashIsEnabled: appConfig.isModuleEnabled(ModuleConfig.HASH_PASSWORD),
          data: {
            Uuid: uuid
          }
        })
        .then(ServiceHandler.checkSuccess<UserLoginData>(UserLoginData))
        .catch(ServiceHandler.catchError())
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async login (data: { loginId: string; password: string }) : Promise<UserLoginData> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CLIENT_LOGIN)

      return axios
        .post('/api/auth/login', {
          target: serviceUrl,
          hashIsEnabled: appConfig.isModuleEnabled(ModuleConfig.HASH_PASSWORD),
          data: {
            CredentialLogin: data.loginId,
            CredentialPwd: data.password
          }
        })
        .then(ServiceHandler.checkSuccess<UserLoginData>(UserLoginData))
        .catch(ServiceHandler.catchError())
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async verifyLogin (token?: string) : Promise<UserLoginData> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CLIENT_VERIFY_LOGIN)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientToken: token
              ? {
                ClientTokenPlatform: 'web',
                ClientTokenCode: token
              }
              : null
          }
        })
        .then(ServiceHandler.checkSuccess<UserLoginData>(UserLoginData))
        .catch(ServiceHandler.catchError())
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new AuthService()
