import { createStore } from 'vuex'
import DialogModule from './modules/dialog.module'
import AuthModule from './modules/auth.module'
import CartModule from './modules/cart.module'
import PinpadModule from './modules/pinpad.module'
import MenuModule from './modules/menu.module'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    DialogModule,
    AuthModule,
    CartModule,
    PinpadModule,
    MenuModule
  }
})
