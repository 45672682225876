import { Actions, Mutations } from '@/config/store.config'
import Product from '@/models/menu/product.model'
import { ProductGroup } from '@/models/menu/productgroup.model'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

const FAVORITES_CONFIG_KEY = 'favoritesStorageData'

export interface MenuStateInfo {
  favoriteProducts: Product[]
}

@Module
export default class MenuModule extends VuexModule implements MenuStateInfo {
  favoriteProducts = ((): Product[] => {
    try {
      return JSON.parse(localStorage.getItem(FAVORITES_CONFIG_KEY) ?? '[]')
    } catch (err) {
      return []
    }
  })()

  get favoriteProductList () : Product[] {
    return this.favoriteProducts
  }

  @Mutation
  [Mutations.SET_FAVORITE_PRODUCTS] (products: Product[]): void {
    this.favoriteProducts = products
    localStorage.setItem(FAVORITES_CONFIG_KEY, JSON.stringify(products))
  }

  @Action
  [Actions.SET_FAVORITE_PRODUCTS] (products: Product[]): void {
    this.context.commit(Mutations.SET_FAVORITE_PRODUCTS, products)
  }

  @Action
  [Actions.CLEAR_MENU_CACHE] (): void {
    this.context.commit(Mutations.SET_FAVORITE_PRODUCTS, [])
  }
}
