import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'

import authService from '@/services/auth/auth.service'
import router from '@/router'
import { Nullable } from '@/utils/customtypes.util'
import { User } from '@/models/client/user.model'
import { Actions, Mutations } from '@/config/store.config'
import UserLoginData from '@/models/responses/userlogin.response'
import GeneralConfig from '@/config/general.config'

export interface UserAuthInfo {
  user: Nullable<User>
  lastCheck: Nullable<number>
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = ((): Nullable<User> => {
    try {
      const userJson = JSON.parse(localStorage.getItem('user') ?? 'null')
      const initialUser = userJson ? new User().from(userJson) : null

      return initialUser
    } catch (err) {
      return null
    }
  })()

  lastCheck = ((): Nullable<number> => {
    const lastCheck = localStorage.getItem('lastcheck') ?? null
    return lastCheck ? +lastCheck : null
  })()

  get currentUser (): User {
    return new User().from(this.user ?? new User())
  }

  get isAuthenticated (): boolean {
    return !!this.user
  }

  @Mutation
  [Mutations.SET_AUTH] (user: User) : void {
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('lastcheck', new Date().getTime().toString())
  }

  @Mutation
  [Mutations.SET_USER] (user: User) : void {
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
  }

  @Mutation
  [Mutations.PURGE_AUTH] () : void {
    this.user = null
    localStorage.removeItem('user')
    localStorage.removeItem('lastcheck')
  }

  @Action
  public async [Actions.LOGIN] (user: { loginId: string; password: string }) : Promise<Nullable<User>> {
    return authService
      .login(user)
      .then((data: UserLoginData) => {
        this.context.commit(Mutations.SET_AUTH, data.User)
        return Promise.resolve(data.User)
      })
      .catch((error: Error) => {
        return Promise.reject(error)
      })
  }

  @Action
  public async [Actions.SIGNUP_GUEST] (uuid: string) : Promise<Nullable<User>> {
    return authService
      .signupGuest(uuid)
      .then((data: UserLoginData) => {
        this.context.commit(Mutations.SET_AUTH, data.User)
        return Promise.resolve(data.User)
      })
      .catch((error: Error) => {
        return Promise.reject(error)
      })
  }

  @Action
  public [Actions.LOGOUT] () : void {
    this.context.commit(Mutations.PURGE_AUTH)
  }

  @Action
  public async [Actions.VERIFY_AUTH] () : Promise<Nullable<User>> {
    if (!this.isAuthenticated) {
      this.context.commit(Mutations.PURGE_AUTH)
      return Promise.resolve(null)
    }

    const lastCheck = this.lastCheck
      ? new Date().getTime() - this.lastCheck
      : -1

    const isInTime = lastCheck >= GeneralConfig.AUTH_MAX_MINUTES * 60_000
    if (isInTime) {
      return Promise.resolve(this.user)
    }

    return authService
      .verifyLogin()
      .then((data: UserLoginData) => {
        this.context.commit(Mutations.SET_AUTH, this.user)
        return data.User
      })
      .catch(() => {
        this.context.commit(Mutations.PURGE_AUTH)
        router.push('/')
        return null
      })
  }
}
