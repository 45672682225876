import {createApp} from '@vue/runtime-dom'
import VueQRCodeComponent from 'vue-qrcode-component'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import LottieAnimation from 'lottie-web-vue'

const app = createApp(App)
const flavor = process.env.VUE_APP_FLAVOR ?? 'default';
console.log(process.env)
console.log(flavor)
app.provide('FLAVOR', flavor)

app
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(LottieAnimation)
  .use(ConfirmationService)

app.component('qr-code', VueQRCodeComponent)
app.mount('#app')
