
import { Options, setup, Vue } from 'vue-class-component'
import Dialog from 'primevue/dialog'
import { computed, reactive, toRefs } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import {
  Dialogs,
  ErrorDialogState
} from '@/store/modules/dialog.module'
import { Nullable } from '@/utils/customtypes.util'
import { Actions } from '@/config/store.config'
import store from '@/store'

interface ErrorState {
  visible: boolean
}

@Options({
  name: 'error-dialog',
  components: {
    Dialog
  }
})
export default class ErrorDialog extends Vue {
  context = setup(() => {
    const store = useStore()
    const state = reactive<ErrorState>({
      visible: false
    })

    const isVisible = computed<boolean>(() => {
      return store.getters.isErrorVisible(Dialogs.Error)
    })

    const data = computed<Nullable<ErrorDialogState>>(() =>
      store.getters.dialogData(Dialogs.Error)
    )

    watch(
      () => isVisible.value,
      () => {
        state.visible = isVisible.value
      }
    )

    watch(
      () => state.visible,
      (value) => {
        if (!value) {
          this.hide()
        }
      }
    )

    return {
      ...toRefs(state),
      data
    }
  })

  public hide () : void {
    const callback = this.context.data?.callback
    callback && callback()

    store.dispatch(Actions.HIDE_ALL_ERRORS)
  }
}
