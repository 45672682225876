import { Module, VuexModule } from 'vuex-module-decorators'

import { Nullable } from '@/utils/customtypes.util'

const PINPAD_CONFIG_KEY = 'PINPAD_CONFIG'

export interface PinpadConfigInfo {
  id: string,
  username: string
  password: string
  environment: string
  ignoreOnStart: boolean
}

export interface PinpadInfo {
  config: Nullable<PinpadConfigInfo>
}

@Module
export default class PinpadModule extends VuexModule implements PinpadInfo {
  config = ((): Nullable<PinpadConfigInfo> => {
    try {
      const configJson = JSON.parse(
        localStorage.getItem(PINPAD_CONFIG_KEY) ?? 'null'
      )

      return configJson as PinpadConfigInfo
    } catch (err) {
      return null
    }
  })()

  get pinpadConfig (): Nullable<PinpadConfigInfo> {
    return this.config
  }
}
