import { DivisionGroup } from "@/models/menu/divisiongroup.model"
import { Modifier } from "@/models/menu/product.model"
import SerializableObject from "@/utils/models/serializableobject.model"
import BranchModel from '@/models/branch/branch.model'
import PaymentType from "./paymenttype.model"
import { ProductPromotionType } from "../menu/productpromotiontype.model"

export default class Cart extends SerializableObject {
  OrderId?: number

  BranchId?: number
  BranchName?: string

  OrderSubTotal?: number
  OrderChargeFee?: number
  OrderDiscount?: number
  OrderTotal?: number
  OrderTip?: number

  OrderLatitude?: number
  OrderLongitude?: number

  OrderIsPickUp = false
  OrderIsScheduled = false

  AddressId?: number

  Branch?: BranchModel
  ProductList: CartProduct[] = []
  SuggestedProductList: CartSuggestedProduct[] = []

  PaymentTypeList: PaymentType[] = []
}

export interface CartProduct {
  OrderProductId: number
  ProductId: number
  ProductExternalId: string

  ProductName: string
  ProductImage: string
  ProductComments?: string

  ProductPrice: number
  ProductQuantity: number
  ProductPromotionQuantity: number

  ProductTotal: number
  ProductFinal: number

  ProductIsCoupon: boolean
  ProductWeight?: number
  ProductVolume?: number

  PromotionType?: ProductPromotionType

  DivisionGroup: DivisionGroup
  ModifierList: CartProductItem[]
}

export interface CartProductItem {
  OrderProductItemId: number

  ItemId: number
  ItemExternalId: number

  ModifierId: number
  ModifierExternalId: string

  ModifierName: string
  ItemName: string

  ItemPrice: number
  ItemQuantity: number

  OrderProductItemParentId?: number
  ItemParentId?: number

  Modifiers?: CartProductItem[]
}

export interface CartSuggestedProduct {
  ProductId: number

  ProductName: string
  ProductImage: string
  ProductDescription?: string

  ProductPrice: number
  ProductIsAvailable: boolean

  ProductIsExternalAvailable: number
  ModifierList: Modifier[]
}
