import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "color-primary"
}
const _hoisted_2 = {
  key: 1,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.context.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.context.visible) = $event)),
    class: "simple-dialog"
  }, {
    default: _withCtx(() => [
      (_ctx.context.data?.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.context.data?.title), 1))
        : _createCommentVNode("", true),
      (_ctx.context.data?.message)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.context.data?.message), 1))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: "button btn-accept",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hide()))
      }, "Aceptar")
    ]),
    _: 1
  }, 8, ["visible"]))
}