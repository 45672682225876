import axios from 'axios'
import ServiceHandler from '@/utils/servicehandler.util'
import ServiceResponse from '@/utils/models/serviceresponse.model'
import GeneralConfig from '@/config/general.config'
import AppConfig from '@/models/appconfig/appconfig.model'

class AllSectionsService {
  public async get (): Promise<AppConfig> {
    const allSectionsJson = JSON.parse(
      localStorage.getItem('allSections') ?? 'null'
    )
    if (allSectionsJson) {
      const allSections = new AppConfig().from(allSectionsJson)

      if (allSections.isValid()) {
        return Promise.resolve(allSections)
      }

      console.info('AppConfig expirado, volviendo a descargar...')
    }

    return axios
      .post<ServiceResponse<AppConfig>>('/api/app/getConfig', {})
      .then(
        ServiceHandler.checkSuccess<AppConfig>(AppConfig, (data: AppConfig) => {
          data.timestamp = new Date().getTime()
          data.appVersion = GeneralConfig.APP_VERSION

          localStorage.setItem('allSections', JSON.stringify(data))
        })
      )
      .catch(ServiceHandler.catchError())
  }
}

export default new AllSectionsService()
