import { Actions, Mutations } from "@/config/store.config"
import Cart from "@/models/cart/cart.model"
import PlaceInputRequest from "@/models/requests/place-input.request"
import CartResponse from "@/models/responses/cart.response"
import cartService from "@/services/cart/cart.service"
import { Nullable } from "@/utils/customtypes.util"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

const BRANCH_CONFIG_KEY = 'BRANCH_CONFIG'
const PHONE_CONFIG_KEY = 'PHONE_CONFIG'

export interface CartConfigInfo {
  BranchId: Nullable<number>
  OrderIsPickUp: boolean,
  ClientPhone: Nullable<string>
}

export interface CartStateInfo {
  cartConfig: CartConfigInfo
  cart: Nullable<Cart>
  pendingPlaceInput: Nullable<PlaceInputRequest>
}

@Module
export default class CartModule extends VuexModule implements CartStateInfo {
  cartConfig: CartConfigInfo = {
    BranchId: (() : Nullable<number> => {
      try {
        return Number(localStorage.getItem(BRANCH_CONFIG_KEY))
      } catch (err) {
        return null
      }
    })(),
    ClientPhone: (() : Nullable<string> => {
      try {
        return localStorage.getItem(PHONE_CONFIG_KEY)
      } catch (err) {
        return null
      }
    })(),
    OrderIsPickUp: true,
  }

  cart: Nullable<Cart> = null
  pendingPlaceInput: Nullable<PlaceInputRequest> = null

  get currentConfig () : CartConfigInfo {
    return this.cartConfig
  }

  get currentCart () : Nullable<Cart> {
    return this.cart
  }

  get pendingPlace () : Nullable<PlaceInputRequest> {
    return this.pendingPlaceInput
  }

  @Mutation
  [Mutations.SET_CART_BRANCH] (id: Nullable<number>) : void {
    this.cartConfig.BranchId = id
  }

  @Mutation
  [Mutations.SET_CLIENT_PHONE] (phone: Nullable<string>) : void {
    if (phone) {
      localStorage.setItem(PHONE_CONFIG_KEY, phone)
    } else {
      localStorage.removeItem(PHONE_CONFIG_KEY)
    }

    this.cartConfig.ClientPhone = phone
  }

  @Mutation
  [Mutations.SET_ORDER_IS_PICKUP] (isPickUp: boolean) : void {
    this.cartConfig.OrderIsPickUp = isPickUp
  }

  @Mutation
  [Mutations.SET_CART] (cart: Nullable<Cart>) : void {
    this.cart = cart
  }

  @Mutation
  [Mutations.SET_CART_TOTALS] (cart: Nullable<Cart>) : void {
    if (!this.cart || !cart) {
      return
    }

    this.cart.OrderSubTotal = cart.OrderSubTotal
    this.cart.OrderTotal = cart.OrderTotal
    this.cart.OrderDiscount = cart.OrderDiscount
    this.cart.OrderChargeFee = cart.OrderChargeFee
  }

  @Mutation
  [Mutations.SET_PENDING_PLACE] (input: Nullable<PlaceInputRequest>) : void {
    this.pendingPlaceInput = input
  }

  @Action
  [Actions.SET_PENDING_PLACE] (input: Nullable<PlaceInputRequest>) : void {
    this.context.commit(Mutations.SET_PENDING_PLACE, input)
  }

  @Action
  [Actions.UPDATE_CONFIG] (config: CartConfigInfo) : void {
    this.context.commit(Mutations.SET_CART_BRANCH, config.BranchId)
    this.context.commit(Mutations.SET_ORDER_IS_PICKUP, config.OrderIsPickUp)
  }

  @Action
  [Actions.SET_CLIENT_PHONE] (phone: string) : void {
    this.context.commit(Mutations.SET_CLIENT_PHONE, phone)
  }

  @Action
  [Actions.UPDATE_CART] () : Promise<Nullable<Cart> | void> {
    if (!this.cartConfig) {
      this.context.commit(Mutations.SET_CART, null)
      return Promise.resolve(null)
    }

    return cartService.getCart(this.cartConfig)
      .then((data: CartResponse) => {
        const cart = data.Cart ? new Cart().from(data.Cart) : null
        if (cart) {
          cart.PaymentTypeList = data.PaymentTypeList ?? []
        }

        this.context.commit(Mutations.SET_CART, cart)
        return cart
      })
      .catch(() => {
        // ignored
      })
  }

  @Action
  [Actions.SET_CART_INFO] () : Promise<Nullable<Cart> | void> {
    if (!this.cartConfig) {
      this.context.commit(Mutations.SET_CART, null)
      return Promise.resolve(null)
    }

    return cartService.setCartInfo(this.cartConfig)
      .then(() => {
        // ignored
      })
      .catch(() => {
        // ignored
      })
  }

  /* @Action
  [Actions.UPDATE_CART_TOTALS] (params: { paymentIsOnline?: boolean, usePoins?: boolean }) : void {
    if (!this.cartConfig) {
      this.context.commit(Mutations.SET_CART, null)
      return
    }

    cartService.getCartTotals(params.paymentIsOnline ?? false, this.cartConfig)
      .then((data: CartResponseData) => {
        const cart = data.Cart ? new Cart().from(data.Cart) : null
        this.context.commit(Mutations.SET_CART_TOTALS, cart)
      })
      .catch(() => {
        // ignored
      })
  } */

  @Action
  [Actions.CART_EMPTY] () : Promise<Nullable<Cart> | void> {
    if (!this.cartConfig || !this.cart) {
      this.context.commit(Mutations.SET_CART, null)
      return Promise.resolve(null)
    }

    const productList = this.cart.ProductList
      .map(product => product.OrderProductId)

    return cartService.removeProductList(productList)
      .then(() => {
        this.context.dispatch(Actions.UPDATE_CART)
      })
  }

  @Action
  [Actions.CART_CLEAR_ALL] () : void {
    this.context.commit(Mutations.SET_CART, null)
    this.context.commit(Mutations.SET_CLIENT_PHONE, null)
    this.context.commit(Mutations.SET_PENDING_PLACE, null)
    this.context.commit(Mutations.SET_ORDER_IS_PICKUP, true)
  }
}
