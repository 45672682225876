export default class SerializableObject {
  from (source: unknown) {
    if (!source) {
      return this
    }

    Object.assign(this, source)
    return this
  }
}
