import { Nullable } from '@/utils/customtypes.util'
import SerializableObject from '@/utils/models/serializableobject.model'
import { User } from '../client/user.model'

class UserLoginData extends SerializableObject {
  User: Nullable<User> = null

  constructor (user?: User) {
    super()

    this.User = user ?? null
  }
}

export default UserLoginData
