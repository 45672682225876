import { AxiosResponse } from 'axios'
import SerializableObject from './models/serializableobject.model'
import ServiceError from './models/serviceerror.model'
import ServiceResponse from './models/serviceresponse.model'

const defaultErrorMessage = 'Tenemos problemas para conectar con el servidor'

class ServiceHandler {
  static checkSuccessEmpty () {
    return (
      response: AxiosResponse<ServiceResponse<null>>
    ): Promise<boolean> => {
      if (response.status !== 200) {
        return Promise.reject(
          new ServiceError(defaultErrorMessage, response.status)
        )
      }

      if (+response.data?.Response?.ReturnCode === 200) {
        return Promise.resolve(true)
      }

      return Promise.reject(
        new ServiceError(
          response.data?.Response?.ReturnMessage ?? defaultErrorMessage,
          +response.data?.Response?.ReturnCode ?? 500
        )
      )
    }
  }

  static checkSuccess<T extends SerializableObject> (
    Type: { new (): T },
    preProcess: ((data: T) => void) | null = null
  ): (response: AxiosResponse<ServiceResponse<T>>) => Promise<T> {
    return async (response: AxiosResponse<ServiceResponse<T>>): Promise<T> => {
      if (response.status !== 200) {
        return Promise.reject(
          new ServiceError(defaultErrorMessage, response.status)
        )
      }

      if (+response.data?.Response?.ReturnCode === 200 && response.data?.Data) {
        const data = new Type().from(response.data.Data)
        preProcess && preProcess(data)
        return Promise.resolve(data)
      }

      return Promise.reject(
        new ServiceError(
          response.data?.Response?.ReturnMessage ?? defaultErrorMessage,
          +response.data?.Response?.ReturnCode ?? 500,
          new Type().from(response.data?.Data)
        )
      )
    }
  }

  static catchError () {
    return (error: any) => {
      const err = new ServiceError(
        error.message ?? defaultErrorMessage,
        error.response?.code ?? 500,
        error.response?.data
      )

      return Promise.reject(err)
    }
  }
}

export default ServiceHandler
