import axios, { AxiosRequestConfig } from 'axios'
import store from '@/store'
import { v4 as uuidV4 } from 'uuid'
import { User } from '@/models/client/user.model'

const instance = axios.create()
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.data.Auth = config.data?.Auth ?? {}
  config.data.Auth.Timestamp = new Date().toISOString()
  config.data.Auth.Signature = uuidV4()

  if (!store.getters.isAuthenticated) {
    return config
  }

  const user = store.getters.currentUser as User
  config.headers = config.headers ?? {}
  config.headers.Authorization = `Bearer ${user.getToken()}`

  return config
})

export default instance
